const getRandomIntInclusive = (min, max) => {
  const randomBuffer = new Uint32Array(1);
  window.crypto.getRandomValues(randomBuffer);
  const randomNumber = randomBuffer[0] / (0xffffffff + 1);
  return randomNumber * (max - min + 1) + min;
};

const mathRandom = (min, max) => {
  const random = Math.random();
  return random * (max - min) + min;
};

export {
  getRandomIntInclusive,
  mathRandom,
};
