import Vue from 'vue';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import VueAMap from 'vue-amap';
import store from './store';
import App from './App.vue';
import router from './router';
import './assets/index.css';

Vue.use(VueAMap);
VueAMap.initAMapApiLoader({
  // 申请地址 https://lbs.amap.com/ 选择web端jsAPI
  key: '6084e44d4d146a230163709305d18758',
  // 插件集合，用到什么插件就使用什么插件
  // plugin: ['AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.PolyEditor', 'AMap.CircleEditor'],
  v: '1.4.4', // 高德sdk版本，最新的sdk已经出到了2.0
  // 官网解释：JSAPI 2.0 提供的开发接口与 1.4 版本达到 99%的兼容度，但是为了保证插件的稳定性我们还是选择1.4.4。
});

Vue.config.productionTip = false;
Vue.use(ElementUI);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
