<template>
  <div class="amap-page-container">
    <el-amap
      vid="amapDemo"
      :center="center"
      :amap-manager="amapManager"
      :zoom="zoom"
      id="amap-demo"
      :events="mapEvents"
    >
      <el-amap-marker
        v-if="mapMarker.position.length"
        :position="mapMarker.position"
        :label="mapMarker.label"
      />
    </el-amap>

    <div id="el-row-bottom">
      <div v-if="highFactor > 0">
        <el-input-number
          id="factor-input"
          v-model="highFactor"
          :min="1"
          :max="10"
          placeholder="区域系数"
          size="small"
          controls-position="right"
        ></el-input-number>
      </div>
      <div>
        <el-button
          id="btn"
          type="primary"
          plain
          @click="handleBtnClicked"
          :loading="btnText === BUTTON_TEXT_MAP.PENDING"
          :disabled="btnText === BUTTON_TEXT_MAP.END"
          >{{ btnText }}
        </el-button>
      </div>
    </div>
  </div>
</template>
<script>
// NPM 方式
import { AMapManager } from 'vue-amap';
import chinaPoints from '../utils/chinaPoints.json';
import { getRandomIntInclusive, mathRandom } from '../utils/random';

const turf = require('@turf/turf');

const BUTTON_TEXT_MAP = {
  START: '开始投掷',
  PENDING: '请稍等',
  STOP: '停止投掷',
  END: '已结束',
};
const MARKER_OFFSET = [-30, -30];
const BTN_TIME_DELAY = 500;
const POS_TIME_DELAY = 0;
const QUADRANT_MAP = {
  0: {
    latitude: [36, 53],
    longitude: [104, 135],
  },
  1: {
    latitude: [36, 53],
    longitude: [73, 104],
  },
  2: {
    latitude: [19, 36],
    longitude: [73, 104],
  },
  3: {
    latitude: [19, 36],
    longitude: [104, 135],
  },
};

const amapManager = new AMapManager();
export default {
  data() {
    return {
      btnText: BUTTON_TEXT_MAP.START,
      zoom: 5,
      center: [105, 36],
      amapManager,
      mapEvents: {
        init(o) {
          o.setMapStyle('amap://styles/70198091d0877e84a0c249fab5c2d984'); // 自定义的高德地图的样式
        },
      },
      position: [],
      chinaArea: null,
      highFactor: 0,
      highFacrotQuadrant: 3,
      hardcodeCord: [],
    };
  },
  computed: {
    BUTTON_TEXT_MAP() {
      return BUTTON_TEXT_MAP;
    },
    label() {
      if (this.position.length) {
        const [longitude, latitude] = this.position;
        return {
          content: `${longitude.toFixed(2)}, ${latitude.toFixed(2)}`,
          offset: MARKER_OFFSET,
        };
      }
      return {};
    },
    mapMarker() {
      const { position, label } = this;
      return { position, label };
    },
  },
  created() {
    if (Object.keys(this.$route.query).length > 0) {
      const { la, lo } = this.$route.query;
      this.hardcodeCord = [Number(lo), Number(la)];
    }
    this.chinaArea = turf.polygon([chinaPoints[0]]);
    if (window?.screen?.width < 500) {
      this.zoom = 3;
    } else if (window?.screen?.width >= 500 && window?.screen?.width < 1000) {
      this.zoom = 4;
    }
  },
  mounted() {
    this.safariHacks();
  },
  methods: {
    /*
      点击按钮相关逻辑
    */
    handleBtnClicked() {
      if (this.btnText === BUTTON_TEXT_MAP.START) {
        this.btnText = BUTTON_TEXT_MAP.PENDING;
        setTimeout(() => {
          this.btnText = BUTTON_TEXT_MAP.STOP;
        }, BTN_TIME_DELAY); // 一段时间才允许点击停止
        const timer = setInterval(() => {
          if (
            this.btnText === BUTTON_TEXT_MAP.PENDING
            || this.btnText === BUTTON_TEXT_MAP.STOP
          ) {
            this.position = this.getRandomCoord();
          } else {
            clearInterval(timer);
            // eslint-disable-next-line max-len
            this.position = this.hardcodeCord.length ? this.hardcodeCord : this.getRandomCoord(true);
            this.center = this.position;
            setTimeout(() => {
              this.zoom = 8;
            }, 800);
          }
        }, POS_TIME_DELAY);
      } else if (this.btnText === BUTTON_TEXT_MAP.STOP) {
        this.btnText = BUTTON_TEXT_MAP.END;
      }
    },
    /*
      随机获取经纬度
    */
    getRandomCoord(realRandom = false) {
      return this.highFactor > 0 ? this.getRandomQuadrant() : this.getRandomPos(realRandom);
    },

    getRandomPos(realRandom = false) {
      let latitude;
      let longitude;
      if (realRandom) {
        latitude = getRandomIntInclusive(19, 53); // 纬度
        longitude = getRandomIntInclusive(73, 135); // 经度
      } else {
        latitude = mathRandom(19, 53); // 纬度先扩展至0-33 再平移至20-53
        longitude = mathRandom(73, 135); // 经度
      }
      if (turf.booleanPointInPolygon(turf.point([latitude, longitude]), this.chinaArea)) {
        return [longitude, latitude];
      }
      return this.getRandomPos();
    },
    /*
      随机获取象限
    */
    getRandomQuadrant() {
      // 经度73-133 中心103
      // 纬度20-50 中心36.5
      const quadrant = mathRandom(0, 3 + this.highFactor);
      return this.getMapCoordinate(quadrant);
    },
    /*
      根据象限随机获取经纬度
    */
    getMapCoordinate(quadrant) {
      let floorQuadrant = Math.floor(quadrant);
      if (floorQuadrant > 3) {
        floorQuadrant = this.highFacrotQuadrant;
      }
      const limits = QUADRANT_MAP[floorQuadrant];
      const latitude = mathRandom(...limits.latitude);
      const longitude = mathRandom(...limits.longitude);
      if (turf.booleanPointInPolygon(turf.point([latitude, longitude]), this.chinaArea)) {
        return [longitude, latitude];
      }
      return this.getMapCoordinate(quadrant);
    },
    /*
      解决safari底栏高度遮挡问题
    */
    safariHacks() {
      if (this.isWeChat()) return;
      const windowsVH = window.innerHeight / 100;
      document
        .getElementById('amap-demo')
        .style.setProperty('--vh', `${windowsVH}px`);
      window.addEventListener('resize', () => {
        document
          .getElementById('amap-demo')
          .style.setProperty('--vh', `${windowsVH}px`);
      });
    },
    /*
      判断网页是否在微信中打开
    */
    isWeChat() {
      return /MicroMessenger/i.test(window.navigator.userAgent);
    },
  },
};
</script>

<style scoped>
#amap-page-container {
  width: 100%;
  height: 500px;
}
#factor-input {
  width: 100px;
}
#el-row-bottom {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -10vh;
  height: 50px;
  width: 100%;
}
#btn {
  box-shadow: 0px 0px 5px 3px #bad7fb;
}

#amap-demo {
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
}
</style>
